<!--
// nuxt-ui/layouts/menuFree.vue
-->
<script setup lang="ts"></script>

<template>
  <main class="w-full min-h-screen flex justify-center items-center">
    <slot></slot>
  </main>
  <div class="w-full flex justify-center items-center my-4">
    <a href="/" class="go-home-button"
      ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20" fill="none">
        <path
          d="M6 10.0147C6.00007 9.69651 6.12277 9.39134 6.34113 9.16635L10.9983 4.36635C11.1057 4.25173 11.2341 4.16031 11.3762 4.09742C11.5182 4.03453 11.671 4.00143 11.8256 4.00005C11.9802 3.99866 12.1335 4.02902 12.2766 4.08936C12.4197 4.1497 12.5497 4.2388 12.659 4.35147C12.7683 4.46414 12.8548 4.59813 12.9133 4.7456C12.9718 4.89308 13.0013 5.05109 13 5.21043C12.9986 5.36976 12.9665 5.52723 12.9055 5.67363C12.8445 5.82004 12.7558 5.95245 12.6446 6.06315L8.81058 10.0147L12.6446 13.9663C12.8566 14.1927 12.974 14.4958 12.9713 14.8104C12.9687 15.1251 12.8462 15.426 12.6304 15.6485C12.4145 15.871 12.1225 15.9972 11.8172 16C11.5119 16.0027 11.2178 15.8817 10.9983 15.6631L6.34113 10.8631C6.12277 10.6382 6.00007 10.333 6 10.0147Z"
          fill="#686868"
        /></svg
      >{{ $t("landing.stores.goHome.button") }}</a
    >
  </div>
</template>

<style scoped>
.go-home-button {
  @apply px-4 py-2 lg:px-0 lg:py-0 lg:pt-6 text-gray-400 text-sm flex items-center text-center self-end;
}
</style>
